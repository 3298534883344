<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.debit_tickets") }}
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          class="elevation-1"
        >
          <template slot="item" slot-scope="row">
            <tr :active="row.selected">
              <td class="text-no-wrap">
                {{ row.item.asset.name }}
              </td>
              <td>
                {{ row.item.borrowDate | formattedDate }}
              </td>
              <td>
                {{ row.item.dueDate | formattedDate }}
              </td>
              <td>
                {{ row.item.giveBackDate | formattedDate }}
              </td>
              <td>
                {{ row.item.quantity || "-" }}
              </td>
              <td class="text-center">
                <v-btn
                  icon
                  color="primary"
                  :loading="loadings[row.index]"
                  @click="downloadForm(row.item.id, row.index)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </td>
              <td class="text-center">
                <v-btn icon color="primary" @click="onTransferClick(row.item)">
                  <v-icon>mdi-send</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <transfer-dialog v-model="transferDialogStatus" :debit-ticket="selectedDebitTicket" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description Performance Component Logic
   * @date 28.07.2020
   */
  import { DEBIT_TICKETS } from "../../query";

  export default {
    name: "DebitTicket",
    components: {
      TransferDialog: () => import("./components/TransferDialog")
    },
    data: vm => ({
      transferDialogStatus: false,
      selectedDebitTicket: null,
      table: {
        loading: false,
        data: [],
        criteria: {
          employee: null
        },
        options: {},
        pagination: {
          rowsPerPage: 10,
          sortBy: "asset.name",
          descending: false
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.debit_tickets.asset"),
            align: "left",
            sortable: true,
            value: "asset",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.debit_tickets.borrow_date"),
            align: "left",
            sortable: true,
            value: "borrowDate",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.debit_tickets.due_date"),
            align: "left",
            sortable: true,
            value: "dueDate",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.debit_tickets.give_back_date"),
            align: "left",
            sortable: true,
            value: "giveBackDate",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.debit_tickets.quantity"),
            align: "left",
            sortable: true,
            value: "quantity",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.debit_tickets.download_form"),
            sortable: false,
            class: ["primary--text"],
            align: "center",
            width: "8%"
          },
          {
            text: vm.$t("hr.debit_tickets.transfer"),
            sortable: false,
            class: ["primary--text"],
            align: "center",
            width: "8%"
          }
        ]
      },
      loadings: []
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.table.criteria.employee = this.$store.state.auth.user.id;
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      downloadForm(id, index) {
        this.$set(this.loadings, index, true);
        this.$api.debitTicketService
          .debitTicketForm(id)
          .then(({ data }) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `zimmet_formu_${this.$moment().format("DD-MM-YYYY-HH-mm-ss")}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentElement.removeChild(link);
          })
          .catch(console.error)
          .finally(() => this.$set(this.loadings, index, false));
      },
      onTransferClick(debitTicket) {
        this.selectedDebitTicket = debitTicket;
        this.transferDialogStatus = true;
      },
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: DEBIT_TICKETS,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors && !data.error) {
              this.table.data = data.filterWithPaginateDebitTickets.content || [];
              this.table.totalItems = data.filterWithPaginateDebitTickets.pageInfo.totalElements || 0;
              this.loadings = new Array(this.table.data.length).fill(false);
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>

<style scoped></style>
